import { Carousel } from "react-carousel-minimal";
import "./Events.css";
const BTS1 = require("./assets/BTS1.jpg");
const Work1 = require("./assets/preworkshop1.JPG");
const Work2 = require("./assets/preworkshop2.JPG");
const Work3 = require("./assets/preworkshop3.JPG");
const Work4 = require("./assets/preworkshop4.JPG");
const Work5 = require("./assets/preworkshop5.JPG");
const Springer = require("./assets/preworkshop_springer.JPG");
const Day11 = require("./assets/Day11.JPG");
const Day12 = require("./assets/Day12.JPG");
const Reg = require("./assets/Register.JPG");
const Day13 = require("./assets/Day13.JPG");
const Day14 = require("./assets/Day14.JPG");
const Mor5 = require("./assets/morning5.JPG");
const Group = require("./assets/CompFlu 24 Group Pic 4.jpg");

function Events() {
  const data = [
    { 
      image: BTS1,
      caption: "BTS: Getting ready for CompFlu-2024"
    },
    {
      image: Work1,
      caption: "Workshop: Talk by Dr. Anupam Sengupta"
    },
    {
      image: Work2,
      caption: "Workshop: Talk by Dr. Federico Toschi"
    },
    {
      image: Work3,
      caption: "Workshop: Talk by Dr. Takashi Kuroiwa"
    },
     {
      image: Work4,
      caption: "Workshop: Talk by Dr. Abhijit P. Deshpande"
    },
    {
      image: Work5,
      caption: "Workshop: Talk by Dr. Alan R Jacob"
    },
    {
      image: Springer,
      caption: "Workshop: Paper writing by Springer Nature"
    },
    {
      image: Day11,
      caption: "Conference Day 1: Opening Ceremony"
    },
    {
      image: Day12,
      caption: "Conference Day 1: Opening Ceremony"
    },
    {
      image: Reg,
      caption: "Registration Desk"
    },
    {
      image: Day13,
      caption: "Conference Day 1"
    },
    {
      image: Day14,
      caption: "Conference Day 1"
    },
    {
      image: Mor5,
      caption: "Conference Day 1"
    },
    {
      image: Group,
      caption: "Group Photo",
    }
  
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="events" style={{zIndex:-40}}>
      <div>
        <div className="heading1">Events</div>
        <div
          style={{
            padding: "0 20px",
          }}
        >
          <Carousel
            data={data}
            time={2000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
              zIndex: "-5000"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Events;
