import React from "react";
const latexTemp = require("./assets/CompFlu2024_BoA_ver4_1.pdf");

function BAbstracts() {
  return (
    <div className="detailed">
      <div className="heading1">Book of Abstracts</div>
      <div className="abstract_docs">
        <div className="heading4a tech_align1">
          <a
            className="abstract_link"
            href={latexTemp}
            download={"CompFlu2024_BoA_ver4_1.pdf"}
          >
            Click Here
          </a>
          {" "} to download the Book of Abstracts
        </div>
      </div>
    </div>
  );
}

export default BAbstracts;
